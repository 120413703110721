nav {
  background-color: #91aec599;
  height: 80px;
  width: 96%;
  margin: 5px 20px;
  border-radius: 50px;
}

.enlace {
  /*position: absolute;*/
  padding: 15px 50px;
}

.logo {
  height: 50px;
  margin-top: 15px;
}

nav ul {
  float: right;
  margin-right: 55px;
}

nav ul li {
  background: #1e5077cc;
  display: inline-block;
  line-height: 40px;
  margin: 10px 3px;
  padding: 10px;
  height: 60px;
  width: 104px;
  border-radius: 50px;
  text-align: center;
}

nav ul li a {
  color: #fff;
  font-size: 16px;
}

/*li a:hover {
    background: #47789e;
    transition: 0.5s;
  }*/

.burguer {
  display: inline;
  margin: 14px 20px 0px 0px;
  cursor: pointer;
  float: right;
  display: none;
}

/*resposive para tablet*/
@media (max-width: 1002px) {
  nav {
    height: 60px;
    margin-left: 15px;
  }
  .enlace {
    padding: 15px 40px;
  }

  .logo {
    height: 40px;
    margin-top: 10px;
  }

  nav ul {
    margin-right: 35px;
  }

  nav ul li {
    margin: 5px 3px;
    padding: 5px;
    height: 50px;
    width: 90px;
    border-radius: 30px;
  }

  nav ul li a {
    font-size: 14px;
  }
}

/*resposive para tablet2*/
@media (max-width: 768px) {
  nav ul {
    margin-right: 35px;
  }
  nav ul li {
    width: 80px;
  }
}

/*resposive para mobil*/
@media (max-width: 656px) {
  nav {
    width: 98%;
    margin-left: 5px;
    background-color: #0d253899;
  }
  .enlace {
    padding: 5px 25px;
  }

  .logo {
    height: 30px;
    margin-top: 15px;
  }

  .burguer {
    display: block;
  }

  .burguer.active {
    display: block;
    background: #1e5077cc;
    padding: 0px 0px 11px 141px;
  }

  .links {
    display: none;
  }

  .links.active {
    margin-top: 59px;
    margin-right: -176px;
    display: block;
  }

  nav ul li {
    display: block;
    line-height: 15px;
    margin: 0px;
    width: 175px;
    height: 35px;
    border-radius: 0px;
  }
}
/*resposive para mobil2*/
@media (max-width: 400px) {
  .burguer.active {
    padding-left: 95px;
  }

  .links.active {
    margin-top: 48px;
    margin-right: -130px;
  }

  nav ul li {
    width: 130px;
  }
}
/*resposive para mobil3*/
@media (max-width: 329px) {
  .burguer.active {
    padding-left: 55px;
  }

  .links.active {
    margin-right: -90px;
  }

  nav ul li {
    width: 90px;
  }
}

/*resposive para mobil4*/
@media (max-width: 303px) {
  .logo {
    height: 26px;
    margin-top: 19px;
  }
  .burguer.active {
    padding-left: 35px;
  }

  .links.active {
    margin-right: -70px;
  }

  nav ul li {
    width: 70px;
  }
}
