.textoContacto h1 {
  text-align: center;
  color: #1e5077;
  font-size: 48px;
  font-family: 'Courgette', cursive;
  padding: 40px 45px 15px;
}
.textoContacto p {
  text-align: center;
  font-size: 20px;
  font-family: 'Abel', sans-serif;
  margin: 0px 50px 10px;
  color: #212025;
}
.ubicacion {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.mapUbicacion {
  width: 460px;
  height: 460px;
  margin: 20px 10px 20px 20px;
}
form {
  width: 460px;
  height: 460px;
  margin: 20px;
  background: #103c5dd6;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
}

.form h2 {
  font-family: 'Courgette', cursive;
  font-size: 25px;
  text-align: left;
  color: #ffffff;
  margin: 25px 40px 5px;
}
.form h3 {
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
  text-align: left;
  color: #ffffff;
  margin: 0px 40px 25px;
}

label {
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
  text-align: left;
  color: #ffffff;
  margin: 40px;
}

input,
textarea {
  width: 83%;
  padding: 12px 10px 12px;
  margin: 10px 40px;
  box-sizing: border-box;
  background: #2a506cbb;
  color: #ebebeb;
  outline: none;
  resize: none;
  border: 0;
  border-radius: 5px;
  font-family: 'Abel', sans-serif;
  transition: all 0.3s;
  border-bottom: 1px solid #c0c0c1;
}
textarea {
  height: 70px;
}
input:focus,
textarea:focus {
  border-bottom: 3px solid #e1e1e1;
}

/*texto dentro del input*/
input::placeholder,
textarea::placeholder {
  color: #d4d4d4;
  font-size: 15px;
}

/*p:before {
  content: attr(type);
  display: block;
  margin: 10px 0 0;
  font-size: 14px;
  color: #817979;
}*/

button {
  float: center;
  width: 83%;
  padding: 9px;
  margin: 0px 40px;
  font-family: 'Abel', sans-serif;
  border: 1px solid #8e8e91;
  border-radius: 5px;
  background: #1e445ed6;
  color: #d8d8d8;
  cursor: pointer;
  transition: all 0.3s;
}
button:hover {
  background: #062237f5;
  color: #fff;
}

/*resposive para table*/
@media (max-width: 1002px) {
  .textoContacto h1 {
    font-size: 40px;
  }
  .textoContacto p {
    font-size: 18px;
  }
  .form {
    width: 370px;
    height: 400px;
    margin: 15px;
  }
  .form h2 {
    font-size: 20px;
    margin: 20px 40px 5px;
  }
  .form h3 {
    font-size: 12px;
    margin: 0px 40px 15px;
  }
  label {    
    margin: 10px 40px 5px;
  }
  .mapUbicacion {
    width: 370px;
    height: 400px;
    margin: 15px;
  }
  input {
    width: 78%;
    padding: 8px 15px 8px;
    margin: 8px 40px;
  }
  textarea {
    width: 78%;
    height: 60px;
    margin: 8px 40px 12px;
  }
  input::placeholder,
  textarea::placeholder {
    font-size: 13px;
  }

  button {
    float: center;
    width: 78%;
    margin: 0px 40px;
  }
}

/*resposive para tablet2*/
@media (max-width: 819px) {
  .textoContacto p {
    font-size: 18px;
  }
  .form {
    width: 320px;
    height: 400px;
  }
  .imgUbicacion {
    width: 350px;
    height: 400px;
  }
  .form h2 {
    font-size: 20px;
    margin: 20px 20px 5px;
  }
  .form h3 {
    font-size: 12px;
    margin: 0px 20px 15px;
  }
  label {
    margin: 10px 20px 5px;
  }
  input {
    width: 87%;
    margin: 8px 20px;
  }
  textarea {
    width: 87%;
    margin: 8px 20px 12px;
  }
  button {
    float: center;
    width: 87%;
    margin: 0px 20px;
  }
  input::placeholder,
  textarea::placeholder {
    font-size: 12px;
  }
}

/*resposive para mobil1*/
@media (max-width: 768px) {
  .textoContacto h1 {
    font-size: 35px;
  }
  .ubicacion {
    margin-bottom: 10px;
    flex-direction: column;
    align-items: center;
  }

  .imgUbicacion {
    width: 280px;
    height: 400px;
  }
  .form {
    width: 370px;
    height: 390px;
  }
  .form h2 {
    margin: 20px 30px 5px;
  }
  .form h3 {
    margin: 0px 30px 15px;
  }
  label {
    font-size: 15px;
    margin: 0px 30px 12px;
  }
  input,
  textarea {
    width: 84%;
    padding: 8px 5px 8px;
    margin: 8px 30px;
  }
  button {
    width: 84%;
    margin: 0px 30px;
  }
}

/*resposive para mobil2*/
@media (max-width: 650px) {
  .textoContacto h1 {
    font-size: 30px;
  }
  .mapUbicacion {
    width: 320px;
    height: 320px;
    margin: 10px 10px 20px;
  }
  .form {
    width: 320px;
    height: 400px;
    margin: 0px 30px 50px;
  }
  label {
    margin: 0px 30px 10px;
  }
  input,
  textarea {
    width: 81%;
  }
  button {
    width: 81%;
    margin: 0px 30px;
  }
}

/*resposive para mobil3*/
@media (max-width: 365px) {
  .mapUbicacion {
    width: 250px;
    height: 250px;
  }
  .form {
    width: 250px;
    height: 400px;
  }
  .form h2 {
    font-size: 18px;
    margin: 20px 20px 5px;
  }
  .form h3 {
    margin: 0px 20px 20px;
  }
  label {
    font-size: 15px;
    margin: 0px 20px 15px;
  }
 
  input,
  textarea {
    margin: 8px 20px;
  }
  button {
    width: 81%;
    margin: 0px 20px;
  }
}
