$colors: (
  text-primary: #817979,
  text-secondary: #1e5077,
  background-primary: #fff,
  background-secondary: #1e5077,
  background-footer: #dcdcdc,
  form:(
    primary-background:#103c5dd6,
    primary-text: #fff,
    input-text:#ebebeb,
    input-background: #2a506cbb,
  ),
  buttons: (
    primary-background: #1e445ed6,
    primary-text: #fff,
    primary-active-background: #fff,
    primary-active-text: #333,
    primary-active-border: #333,
    secondary-background: #fff,
    secondary-text: #333,
    secondary-active-background: #333,
    secondary-active-text: #fff,
    secondary-active-border: #fff,
    outline-background: transparent,
    outline-text: #333,
    outline-border: #333,
    outline-active-background: #fff,
    outline-active-text: #333,
    secondary-outline-background: transparent,
    secondary-outline-text: #fff,
    secondary-outline-border: #fff,
    secondary-outline-active-background: #333,
    secondary-outline-active-text: #fff,
  ),
  cards: (
    primary-background:#ffffff6b,
    secondary-background: #ffffff1a,
    border-card: #00000026,
  ),
  footer: (
    primary-background:#1E5077,
    text-primary: #FFFFFF,
  ),
  

)!default;

$footer-background: #1e5077;
$text-white:#fff;
