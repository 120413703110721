.servicios {
  background: #1e5077;
  padding-bottom: 70px;
}

.servicios h1 {
  text-align: center;
  color: #ffffff;
  font-size: 48px;
  font-family: 'Courgette', cursive;
  padding: 40px 45px 40px;
}

.p {
  text-align: center;
  font-size: 20px;
  font-family: 'Abel', sans-serif;
  margin: 0px 50px 10px;
  color: #ffffff;
}

.servicios1 {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.vista {
  background: rgba(255, 255, 255, 0.42);
  width: 375px;
  height: 250px;
}
.vista2 {
  background: rgba(255, 255, 255, 0.42);
  width: 375px;
  height: 250px;
}

.p1 {
  text-align: center;
  font-size: 20px;
  font-family: 'Nunito', sans-serif;
  padding: 67px 22px;
  margin: 30px 30px;
  background: rgb(255 255 255 / 10%);
  border: 0.3px solid #00000026;
}

.p2 {
  text-align: center;
  font-size: 20px;
  font-family: 'Nunito', sans-serif;
  padding: 54px 30px;
  margin: 30px 30px;
  background: rgb(255 255 255 / 10%);
  border: 0.3px solid #00000026;
}

.img1 {
  margin: 0px;
  width: 375px;
}

.img2 {
  margin: 0px;
  width: 375px;
}

.lina {
  margin: 0px;
  width: 375px;
  height: 248px;
}

/*resposive para tablet*/
@media (max-width: 1002px) {
  .servicios h1 {
    font-size: 40px;
  }

  .servicios p {
    font-size: 18px;
  }

  .p1 {
    padding: 57px 13px;
    margin: 27px 27px;
  }

  .p2 {
    padding: 45px 14px;
    margin: 27px 27px;
  }

  .vista {
    width: 330px;
    height: 220px;
  }
  .vista2 {
    width: 330px;
    height: 220px;
  }

  .img1 {
    width: 330px;
    height: 220px;
  }

  .img2 {
    width: 330px;
    height: 220px;
  }
}

/*resposive para tablet2*/
@media (max-width: 800px) {
  .servicios p {
    font-size: 17px;
  }

  .p1 {
    padding: 51px 10px;
    margin: 25px 25px;
  }

  .p2 {
    padding: 40px 12px;
    margin: 25px 25px;
  }

  .vista {
    width: 300px;
    height: 200px;
  }
  .vista2 {
    width: 300px;
    height: 200px;
  }

  .img1 {
    width: 300px;
    height: 200px;
  }

  .img2 {
    width: 300px;
    height: 200px;
  }
}

/*resposive para mobil1*/
@media (max-width: 768px) {
  .servicios h1 {
    font-size: 35px;
  }
  .servicios p {
    font-size: 15px;
  }

  .p1 {
    padding: 41px 5px;
    margin: 20px 20px;
  }

  .p2 {
    padding: 31px 5px;
    margin: 20px 20px;
  }

  .vista {
    width: 250px;
    height: 165px;
  }
  .vista2 {
    width: 250px;
    height: 165px;
  }
  .img1 {
    width: 250px;
    height: 165px;
  }

  .img2 {
    width: 250px;
    height: 165px;
  }
}

/*resposive para mobil2*/
@media (max-width: 650px) {
  .servicios h1 {
    font-size: 30px;
  }
  .servicios1 {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .servicios1:nth-child(even) {
    flex-direction: column-reverse;
  }
}
