.textoTarifas h1 {
  text-align: center;
  color: #1e5077;
  font-size: 48px;
  font-family: 'Courgette', cursive;
  padding: 40px 45px 15px;
}
.textoTarifas p {
  font-family: 'Abel', sans-serif;
  text-align: center;
  font-size: 20px;
  margin: 0px 220px 40px;
}

.tarifas {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.imgTarifas {
  width: auto;
  height: 460px;
  margin: 20px;
}

/*resposive para table*/
@media (max-width: 1002px) {
  .textoTarifas h1 {
    font-size: 40px;
  }
  .textoTarifas p {
    font-size: 18px;
  }
  .imgTarifas {
    height: 370px;
    margin: 15px;
  }
}

/*resposive para tablet2*/
@media (max-width: 800px) {
  .imgTarifas {
    height: 320px;
  }
}

/*resposive para mobil1*/
@media (max-width: 768px) {
  .textoTarifas h1 {
    font-size: 35px;
  }
  .textoTarifas p {
    font-size: 15px;
    margin: 0px 120px 25px;
  }
  .imgTarifas {
    height: 280px;
  }
}

/*resposive para mobil2*/
@media (max-width: 650px) {
  .textoTarifas h1 {
    font-size: 30px;
  }

  .textoTarifas p {
    margin: 0px 80px 25px;
  }

  .tarifas {
    margin-bottom: 10px;
    flex-direction: column;
    align-items: center;
  }
  .imgTarifas {
    height: 320px;
    margin: 10px;
  }
}

/*resposive para mobil3*/
@media (max-width: 365px) {
  .imgTarifas {
    height: 250px;
  }
}
