.textoCasas h1 {
  text-align: center;
  color: #1e5077;
  font-size: 48px;
  font-family: 'Courgette', cursive;
  padding: 40px 45px 10px;
}

.textoCasas p {
  text-align: center;
  font-size: 20px;
  font-family: 'Abel', sans-serif;
  margin: 5px 300px 45px;
  color: #212025;
}

.casas {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.img {
  width: auto;
  height: 250px;
  margin: 10px;
}

video {
  margin: 0 auto 50px;
  width: 980px;
  display: block;
}

/*resposive para table*/
@media (max-width: 1002px) {
  .textoCasas h1 {
    font-size: 40px;
  }
  .textoCasas p {
    font-size: 18px;
    margin: 5px 200px 45px;
  }
  .img {
    height: 200px;
    margin: 5px;
  }

  video {
    width: 770px;
  }
}

/*resposive para tablet2*/
@media (max-width: 800px) {
  .img {
    height: 176px;
  }
  video {
    width: 680px;
  }
}

/*resposive para mobil1*/
@media (max-width: 768px) {
  .textoCasas h1 {
    font-size: 35px;
  }
  .textoCasas p {
    font-size: 15px;
    margin: 5px 150px 45px;
  }
  .img {
    height: 150px;
  }

  video {
    width: 580px;
  }
}

/*resposive para mobil2*/
@media (max-width: 650px) {
  .textoCasas h1 {
    font-size: 30px;
  }
  .textoCasas p {
    margin: 5px 105px 45px;
  }

    .casas {
      margin-bottom: 10px;
    flex-direction: column; 
    align-items: center;
  }
  .img {
    height: 250px;
    margin: 10px;
  }


  video {
    width: 315px;
  }
}

/*resposive para mobil3*/
@media (max-width: 365px) {
  .textoCasas p {
    margin: 5px 70px 45px;
  }

  .img {
    height: 190px;
    margin: 10px;
  }

  video {
    width: 240px;
  }
}
