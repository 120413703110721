.containerBoton{
    background-color: #ffffff;
    position: fixed;
    z-index: 999;
    border-radius: 50%;
    bottom: 20px;
    right: 25px;
    padding: 4px 4px 0px;
    transition: ease 0.3s;
    animation: efecto 2s infinite;
}

/*.containerBoton:hover{
    transform: scale(1.1);
    transition: 0.3s;
}*/

.boton{
    width: 40px;
}

@keyframes efecto{
    0%{
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.85);
    }
    100%{
        box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
    }
}

/*resposive para mobil*/
@media (max-width: 650px) {
    .boton{
        width: 30px;
    }
}